import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  chakra,
  Stack,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  ModalFooter,
  Text,
  CloseButton,
} from "@chakra-ui/react";
import MasterLayout from "./master";
import { StaticImage } from "gatsby-plugin-image";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import Seo from "../components/seo/player_seo";

const PlayerLayout = (props) => {
  const { pageContext } = props;
  const { chapter, bookName, bookThumbnail, className } = pageContext;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1500);
  }, []);

  return (
    <MasterLayout>
      <Seo
        title={`${chapter.name} - ${bookName} - SunoKitaab`}
        description={`Audiobook Lecture ${chapter.name} from ${bookName} - ${className}`}
        bookImage={bookThumbnail}
        headline={`${chapter.name} NCERT Lecture`}
        url={`https://sunokitaab.com${props.location.pathname}`}
        meta={[
          {
            property: `og:audio`,
            content: chapter.lectures[0]?.url || "https://f002.backblazeb2.com/file/SunoKitaabtest/tap+the+banner+audio.mp3",
          },
          {
            property: `og:audio:type`,
            content: "audio/mp3",
          },
        ]}
       />
      <Modal isCentered isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent backgroundColor={"#7cd956"}>
          <CloseButton onClick={() => setIsOpen(false)} alignSelf={'end'} p={4} color={'white'} size={"lg"} />
          <ModalBody backgroundColor={"#7cd956"} padding={0}>
            <a href="https://sunokitaab.com/app">
              <Box py={4} maxW={"full"}>
                <StaticImage src={"../images/modal_image.png"} />
              </Box>
            </a>
          </ModalBody>
          <a
            href="https://sunokitaab.com/app"
          >
            <ModalFooter justifyContent={"center"} backgroundColor={"#7cd956"}>
              <Button colorScheme="#7cd956" onClick={() => setIsOpen(false)}>
                <BsFillCloudDownloadFill
                  color="white"
                  style={{ margin: "12px", fontSize: "2rem" }}
                />
                <Text px={"2"} fontSize={"1.3rem"} fontWeight={"bold"}>
                  Get It Now! for FREE
                </Text>
              </Button>
            </ModalFooter>
          </a>
        </ModalContent>
      </Modal>
      <Container maxW={"6xl"}>
        <Stack spacing={4} alignItems={"center"} width={"full"}>
          <Box>
            <chakra.h1 fontSize={"2rem"} fontWeight={"bold"}>
              {chapter.name}
            </chakra.h1>
            <chakra.h2 fontSize={"1.6rem"}>
              {`${bookName} - ${className}`}
            </chakra.h2>
          </Box>
          <Image
            borderRadius={"18px"}
            maxW={{ base: "18rem", sm: "20rem", md: "md", lg: "md" }}
            pt={14}
            src={bookThumbnail}
            alt={bookName}
          />
          <Box width={"full"} px={8} py={12}>
            <AudioPlayer
              style={{ padding: "1rem" }}
              src={chapter.lectures[0]?.url || "https://f002.backblazeb2.com/file/SunoKitaabtest/tap+the+banner+audio.mp3"}
            />
          </Box>
          <a href="https://sunokitaab.com/app">
            <Box maxW={"full"}>
              <StaticImage src={"../images/long_width_app_banner.png"} />
            </Box>
          </a>
        </Stack>
      </Container>
    </MasterLayout>
  );
};

export default PlayerLayout;
